import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const canvasRef = useRef(null)

  const nameArray = ['T', 'a', 'i', 'l', 'o', 'r', 'm', 'a', 'd', 'e', ' ', 's', 'o', 'l', 'u', 't', 'i', 'o', 'n', 's', ' ', 'i', 'n', ' ', 'b', 'u', 'd', 'g', 'e', 't']

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  }, [])

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight

    let stars = []
    const FPS = 60
    const numStars = 50
    const mouse = { x: 0, y: 0 }

    // Initialize stars
    for (let i = 0; i < numStars; i++) {
      stars.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 1 + 1,
        vx: Math.floor(Math.random() * 50) - 25,
        vy: Math.floor(Math.random() * 50) - 25
      })
    }

    // Calculate distance
    const distance = (point1, point2) => {
      const xs = point2.x - point1.x
      const ys = point2.y - point1.y
      return Math.sqrt(xs * xs + ys * ys)
    }

    // Draw stars and lines
    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.globalCompositeOperation = 'lighter'

      // Draw stars
      stars.forEach((star) => {
        ctx.fillStyle = '#D3D3D3'
        ctx.beginPath()
        ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2)
        ctx.fill()
        ctx.fillStyle = 'black'
        ctx.stroke()
      })

      // Draw lines
      ctx.beginPath()
      stars.forEach((star, i) => {
        ctx.moveTo(star.x, star.y)
        if (distance(mouse, star) < 150) ctx.lineTo(mouse.x, mouse.y)
        stars.forEach((star2) => {
          if (distance(star, star2) < 150) {
            ctx.lineTo(star2.x, star2.y)
          }
        })
      })
      ctx.lineWidth = 0.05
      ctx.strokeStyle = 'gray'
      ctx.stroke()
    }

    // Update star positions
    const update = () => {
      stars.forEach((star) => {
        star.x += star.vx / FPS
        star.y += star.vy / FPS

        if (star.x < 0 || star.x > canvas.width) star.vx = -star.vx
        if (star.y < 0 || star.y > canvas.height) star.vy = -star.vy
      })
    }

    const animate = () => {
      draw()
      update()
      requestAnimationFrame(animate)
    }

    // Mousemove listener
    canvas.addEventListener('mousemove', (e) => {
      mouse.x = e.clientX
      mouse.y = e.clientY
    })

    animate()

    // Cleanup
    return () => {
      stars = []
      canvas.removeEventListener('mousemove', () => {})
    }
  }, [])

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1 className="introtext">
            <span className={`${letterClass} _11 logo-class`}>M</span>
            <span className={`${letterClass} _12 logo-class`}>a</span>
            <span className={`${letterClass} _13 logo-class`}>h</span>
            <span className={`${letterClass} _14 logo-class`}>a</span>
            <span className={`${letterClass} _15 logo-class`}>t</span>
            <span className={`${letterClass} _16 logo-class`}>.</span>
            <span className={`${letterClass} _17 logo-class`}>a</span>
            <span className={`${letterClass} _18 logo-class`}>u</span>
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={50}
            />
            <br />
          </h1>
          <h2>API / DATA INTEGRATION / WEB & MOBILE APPS / AI & ML</h2>
          <br />
          <Link to="/contact" className="flat-home-button">
            CONTACT US
          </Link>
        </div>
      </div>
      <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0 }}></canvas>
      <Loader type="ball-rotate" />
    </>
  )
}

export default Home
