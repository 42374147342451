import { useEffect, useState, useRef } from 'react';
import Loader from 'react-loaders';
import AnimatedLetters from '../AnimatedLetters';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const [csrfToken, setCsrfToken] = useState('');
  const [loading, setLoading] = useState(false);

  const form = useRef();

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);

    fetch('https://mahat.au/csrf_token.php')
    .then(response => response.json())
    .then(data => setCsrfToken(data.csrf_token))
    .catch(error => console.error('Error fetching CSRF token:', error));
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = {
      name: form.current.name.value,
      mobile: form.current.mobile.value,
      email: form.current.email.value,
      message: form.current.message.value,
      csrf: csrfToken
    };

    fetch('https://mahat.au/email.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.text())
      .then(data => {
        toast.success('Email sent successfully!', {
          pauseOnHover: true,
          theme: "colored",
        });
        e.target.reset();
        setLoading(false);
      })
      .catch(error => {
        toast.error('Failed to send the email, please try again.', {
          pauseOnHover: true,
          theme: "colored",
        });
        e.target.reset();
        setLoading(false);
      });
  };

  return (
    <>
      <Loader
        type="triangle-skew-spin"
        active={loading}
        style={{ zIndex: 1000 }}
      />
      <div className="container contact-page">
        <div className="text-zone">
          <h1 className='contact-page-title'>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'u', 's']}
              idx={15}
            />
          </h1>
          <span className='contact-form'>
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input placeholder="Name" type="text" name="name" required />
                </li>
                <li className="half">
                  <input
                    placeholder="Your Mobile"
                    type="text"
                    name="mobile"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Your Email"
                    type="email"
                    name="email"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                <p>For free quote &nbsp;    
                  <FontAwesomeIcon
                    icon={faPhone}
                    color="#bebebf"
                    className="anchor-icon"
                  />&nbsp; (+61)0423528740 &nbsp;&nbsp;
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    color="#bebebf"
                    className="anchor-icon"
                  />&nbsp;
                 deepak@mahat.au</p>
                <li>
                  <input type="submit" className="flat-button" value="SEND" />
                </li>
              </ul>
            </form>
          </span>
        </div>
      </div>
      <Loader type="ball-rotate" />
    </>
  );
};

export default Contact;
