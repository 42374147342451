import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'

const Privacy = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
     setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container privacy-page">
        <div className="privacy-text-zone">
          <h1 className="privacy-page-title">
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['P', 'r', 'i', 'v', 'a', 'c', 'y', ' ', 'P', 'o', 'l', 'i', 'c', 'y']}
              idx={15}
            />
          </h1>
          <div className="privacy-policy">
            <p><strong>Effective Date:</strong> 13 January 2025</p>
            <p><strong>Last Updated:</strong> 13 January 2025</p>
            <p>At My Invoices, we are committed to safeguarding your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your data in compliance with global data privacy standards, including the <strong>Australian Privacy Act 1988 (APPs)</strong>, <strong>General Data Protection Regulation (GDPR)</strong>, and <strong>California Consumer Privacy Act (CCPA)</strong>.</p>
            <p>By using the My Invoices app, you agree to this Privacy Policy. If you do not agree, please discontinue using the app.</p>

            <h2>1. Definitions</h2>
            <ul>
              <li><p><strong>Personal Data:</strong> Any information that identifies or can identify an individual, such as email addresses, names, and transaction details.</p></li>
              <li><p><strong>Local Data:</strong> Data stored solely on your device unless you enable syncing.</p></li>
              <li><p><strong>Synced Data:</strong> Data uploaded to our secure servers when you choose to enable sync functionality.</p></li>
              <li><p><strong>Service:</strong> The My Invoices app, including its features and services.</p></li>
              <li><p><strong>You:</strong> The user of the My Invoices app.</p></li>
            </ul>

            <h2>2. Types of Data We Collect</h2>
            <p><strong>a. Local Data (Default)</strong></p>
            <ul>
              <li><p>Scanned invoices and receipts, including extracted details (e.g., items, prices, dates).</p></li>
              <li><p>Search queries and app settings stored locally on your device.</p></li>
            </ul>
            <p><strong>b. Optional Synced Data</strong></p>
            <ul>
              <li><p>Email Address: To create and manage your sync account.</p></li>
              <li><p>Invoice Data: Scanned invoices and their extracted details for secure backup and syncing across devices.</p></li>
            </ul>
            <p><strong>c. Anonymized Usage Data</strong></p>
            <ul>
              <li><p>App usage statistics for improving features and user experience (e.g., crash reports, performance metrics). This data does not identify you.</p></li>
            </ul>

            <h2>3. How We Collect Your Data</h2>
            <ul>
              <li><p>Use the app to scan invoices or manage spending.</p></li>
              <li><p>Enable optional syncing and provide consent for data upload.</p></li>
              <li><p>Interact with anonymized analytics tools, if enabled.</p></li>
              <li><p>Provide feedback or contact our support channels.</p></li>
            </ul>

            <h2>4. Purpose of Collecting Data</h2>
            <ul>
              <li><p>Provide core app functionalities, such as scanning and organizing invoices.</p></li>
              <li><p>Enable optional features like syncing and secure data backup.</p></li>
              <li><p>Improve app performance and user experience through anonymized usage data.</p></li>
              <li><p>Comply with legal obligations and resolve disputes.</p></li>
            </ul>

            <h2>5. Disclosure of Personal Information</h2>
            <ul>
              <li><p><strong>Service Providers:</strong> Trusted third-party services for hosting, analytics, or backups. All service providers comply with applicable data protection standards, including GDPR and APPs.</p></li>
              <li><p><strong>Legal Compliance:</strong> To comply with laws, enforce agreements, or respond to legal requests.</p></li>
              <li><p><strong>Your Consent:</strong> When you explicitly authorize data sharing for specific features or services.</p></li>
              <li><p><strong>Cross-Border Data Transfers:</strong> If your synced data is stored outside your region, we ensure compliance with local data protection standards.</p></li>
            </ul>

            <h2>6. Your Rights</h2>
            <ul>
              <li><p><strong>Access Your Data:</strong> Request a copy of the personal data we hold.</p></li>
              <li><p><strong>Correct Your Data:</strong> Update or correct inaccuracies in your data.</p></li>
              <li><p><strong>Withdraw Consent:</strong> Opt out of optional syncing or data processing.</p></li>
              <li><p><strong>Delete Your Data:</strong> Request deletion of your personal data (subject to legal retention requirements).</p></li>
              <li><p><strong>Data Portability:</strong> Obtain your data in a machine-readable format (where technically feasible).</p></li>
              <li><p><strong>Lodge a Complaint:</strong> Contact us or a relevant data protection authority.</p></li>
            </ul>

            <h2>7. Data Retention</h2>
            <ul>
              <li><p><strong>Local Data:</strong> Stored on your device until deleted by you.</p></li>
              <li><p><strong>Synced Data:</strong> Retained on our servers while your sync account remains active. You can delete this data by contacting support or using the app's settings.</p></li>
            </ul>

            <h2>8. Data Security</h2>
            <p>We implement strict security measures, including:</p>
            <ul>
              <li><p><strong>Encryption:</strong> Data is encrypted during transmission (TLS) and at rest (AES-256).</p></li>
              <li><p><strong>Access Controls:</strong> Restricted access to authorized personnel only.</p></li>
              <li><p><strong>Regular Audits:</strong> Routine security assessments to identify and address vulnerabilities.</p></li>
            </ul>

            <h2>9. Children’s Privacy</h2>
            <p>My Invoices is not intended for children under 13. We do not knowingly collect data from children. If we become aware of such data, we will delete it immediately.</p>

            <h2>10. Complaints and Dispute Resolution</h2>
            <p>If you believe we have violated your privacy rights, you can:</p>
            <ul>
              <li><p><strong>Contact Us:</strong> Email info@mahat.au with your complaint. We will investigate and respond promptly.</p></li>
              <li><p><strong>Contact a Relevant Authority:</strong> 
                <ul>
                  <li><p>Australia: Office of the Australian Information Commissioner (OAIC).</p></li>
                  <li><p>European Union: Your local Data Protection Authority (DPA).</p></li>
                  <li><p>California: California Attorney General’s Office.</p></li>
                </ul>
              </p></li>
            </ul>

            <h2>11. Updates to this Privacy Policy</h2>
            <p>We may update this policy to reflect changes in laws, practices, or app features. Updates will be posted in the app and on our website. Continued use of the app constitutes acceptance of the revised policy.</p>

            <h2>12. Contact Us</h2>
            <p>For questions or privacy-related concerns, please contact us:</p>
            <ul>
              <li><p><strong>Email:</strong> hi@mahat.au</p></li>
              <li><p><strong>Support Page:</strong> https://mahat.au/contact</p></li>
            </ul>
          </div>
        </div>
      </div>
      <Loader type="ball-rotate" />
    </>
  )
}

export default Privacy
