import { useEffect, useState } from 'react'
import {
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
     setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1 className="about-page-title">
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'u', 's']}
              idx={15}
            />
          </h1>
          <p align="LEFT">
          Deepak Mahat <a
            href="https://www.linkedin.com/in/dpk-mht/"
            target="_blank"
            rel="noreferrer"
          >
          <FontAwesomeIcon
              icon={faLinkedin}
              color="#bebebf"
              className="anchor-icon"
            />
          </a>&nbsp;(Software Engineer & IT consultant) has more 15 years of experience in Australian software industry. Completed his Masters of Software Engineering from University of Sydney has worked in diverse project for over a decade.  
     
          </p>
          <p align="LEFT">
          Krishna Mahat <a
            href="https://www.linkedin.com/in/krishna-mahat-0b6406113/"
            target="_blank"
            rel="noreferrer"
          >
          <FontAwesomeIcon
              icon={faLinkedin}
              color="#bebebf"
              className="anchor-icon"
            />
          </a>&nbsp;(Web Application Engineer) has been working as web application engineer in renowned industry in Australia. Completed his Masters of Information Technology from Torrens University has more than 5 years of industry experience as web engineer.          
          </p>
          <p align="LEFT">
          We bring over 15 years of professional expertise in software engineering, ensuring your projects are delivered with the highest quality and at a budget that fits your needs.
          </p>
        </div>
      </div>
      <Loader type="ball-rotate" />
    </>
  )
}

export default About
