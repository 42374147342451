import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
     setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1 className="service-page-title">
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['S', 'e', 'r', 'v', 'i', 'c ', 'e', 's']}
              idx={15}
            />
          </h1>
                    
         <p align="LEFT">IT consultant: We provide expert advice and solutions to help businesses improve their IT infrastructure and strategy.</p>
          <p align="LEFT">Mobile (iOS/Android) development: We create mobile apps for both iOS or Android.</p>
          <p align="LEFT">Web application development: We build and maintain web-based software applications.</p>
          <p align="LEFT">Information security: We protect your valuable data and information systems from cyber threats.</p>
          <p align="LEFT">Digital marketing: We promote products or services using digital channels, such as social media, search engines, and email campaigns.</p>
          <p align="LEFT">Cloud-based services: We deliver computing services (e.g., storage, processing, and software) over the internet, allowing on-demand access and scalability.</p>
          
        </div>
      </div>
      <Loader type="ball-rotate" />
    </>
  )
}

export default About
